import axios from 'axios'
import message from "ant-design-vue/lib/message"
import store from '@/store'
import {getToken, removeToken} from "./auth";
import {removeCookie} from "./storage";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000 // request timeout
})

service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (getToken()) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        if (localStorage.getItem('lang')) {
            if (!config.params) {
                config.params = {}
            }
            config.params['lang'] = localStorage.getItem('lang')
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        // if (!res.errorCode) {
        //
        // }
        if (res.errorCode === "104") {
            message.error(res.message, 1.5, function () {
                removeToken()
                removeCookie('user')
                location.reload()
            })

            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        message.error(error.message)
        return Promise.reject(error)
    }
)

export default service