import request from "@/utils/request"

export function login(data) {
    return request({
        url: '/user/jwtLogin',
        method: 'post',
        data
    })
}

export function transferUserInfo() {
    return request({
        url: '/user/info/transfer',
        method: 'get',
    })
}

export function saveUser(data) {
    return request({
        url: '/user/save',
        method: 'post',
        data: data
    })
}

export function userList(params, data) {
    return request({
        url: '/user/list',
        method: 'post',
        data: data,
        params: {
            ...params
        }
    })
}

export function saveUserRoles(params, data) {
    return request({
        url: '/user/saveUserRoles',
        method: 'post',
        data: data,
        params: {
            ...params
        }
    })
}

export function getUserRoles(params) {
    return request({
        url: '/user/getUserRoles',
        method: 'get',
        params: {
            ...params
        }
    })
}