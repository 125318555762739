import { getToken, setToken, removeToken } from '@/utils/auth'
import { login, transferUserInfo } from '@/api/user'
import {getUserRoles, saveUser, saveUserRoles, userList} from "../../api/user";

const state = {
    token: getToken(),
    name: '',
    avatar: '',
    introduction: '',
    roles: []
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    }
}

const actions = {
    LOGIN({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    USER_LIST({ commit }, data) {
        return new Promise((resolve, reject) => {
            userList(data.params, data.data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    SAVE_USER_ROLES({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveUserRoles(data.params, data.data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    SAVE_USER({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveUser(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    GET_USER_ROLES({ commit }, data) {
        return new Promise((resolve, reject) => {
            getUserRoles(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    TRANSFER_USER({ commit }) {
        return new Promise((resolve, reject) => {
            transferUserInfo().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: false,
    state,
    mutations,
    actions
}
