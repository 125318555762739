import request from "../utils/request";

export function saveQuestionnaire(data) {
    return request({
        url: '/questionnaire/save',
        method: 'post',
        data
    })
}

export function saveQuestionnaireTopic(data) {
    return request({
        url: '/questionnaire/topic/save',
        method: 'post',
        data
    })
}

export function saveQuestionnaireAnswer(data) {
    return request({
        url: '/questionnaire/answer/save',
        method: 'post',
        data
    })
}

export function questionnaireList(param, data) {
    return request({
        url: '/questionnaire/list',
        method: 'post',
        data,
        params: {
            ...param
        }
    })
}

export function getDetail(param) {
    return request({
        url: '/questionnaire/detail',
        method: 'get',
        params: {
            ...param
        }
    })
}

export function deleteQuestionnaire(param) {
    return request({
        url: '/questionnaire/del',
        method: 'delete',
        params: {
            ...param
        }
    })
}

export function deleteQuestionnaireTopic(param) {
    return request({
        url: '/questionnaire/topic/del',
        method: 'delete',
        params: {
            ...param
        }
    })
}