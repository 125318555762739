export default {
    login: 'Login',
    logout: 'Logout',
    add: 'Add',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    lastModifiedDate: 'LastModifiedDate',
    lastModifiedUser: 'LastModifiedUser',
    operation: 'Operation',
    submit: 'Submit',
    upload: 'Upload',
    delConfirmMsg: 'Are you sure to delete it?',
    isCommon: 'Is Common',
    system: 'System',
    custom: 'Custom',
    invalid: 'Invalid',
    effective: 'Effective',
    enable: 'Enable',
    yes: 'Yes',
    no: 'No',
    text: 'Text',
    radio: 'Radio',
    checkbox: 'Multiple Choice',
    preview: 'Preview',
    role: {
        roleManage: 'Role Manage',
        roleList: 'Role List',
        name: 'Name Of Role',
        code: 'Code Of Role',
        type: 'Type Of Role',
        status: 'Status',
        menu: 'Menu of Role'
    },
    rule: {
        moduleName: 'Rule Manage',
    },
    tenant: {
        code: 'Code Of Tenant',
        tenant: 'Tenant',
        tenantManage: 'Tenant Manage',
        name: 'Name Of Tenant',
        status: 'Status',
        logo: 'Logo',
        domain: 'Domain'
    },
    param: {
        paramManage: 'Parameter Manager',
        name: 'Parameter Name',
        code: 'Parameter Code',
        type: 'Parameter Type',
        typeCode: 'Parameter-Type Code',
        typeName: 'Parameter-Type Name',
        status: 'Status',
        detail: 'Detail'
    },
    user: {
        list: 'User List',
        avatar: 'Avatar',
        username: 'Username',
        nickname: 'Nickname',
        mobile: 'Mobile',
        email: 'E-mail',
        role: 'Roles',
        status: 'Status',
        bindTenant: 'Bind Tenant'
    },
    questionnaire: {
        moduleName: 'Questionnaire',
        list: 'Questionnaire List',
        detail: 'Questionnaire Detail',
        my: 'My Questionnaire',
        title: 'Title',
        status: 'Status',
        add: 'Add Topic',
        type: 'Type',
        isRequired: 'Is Required',
        topic: 'Topic',
        option: 'Options',
        addOption: 'Add Option',
        delOption: 'Delete Option'
    }
}