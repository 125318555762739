import request from "../utils/request";

export function saveParamType(data) {
    return request({
        url: '/parameter/type/save',
        method: 'post',
        data
    })
}

export function saveParam(data) {
    return request({
        url: '/parameter/save',
        method: 'post',
        data
    })
}

export function paramTypeList(param, data) {
    return request({
        url: '/parameter/type/list',
        method: 'post',
        data,
        params: {
            ...param
        }
    })
}

export function paramList(param, data) {
    return request({
        url: '/parameter/list',
        method: 'post',
        data,
        params: {
            ...param
        }
    })
}

export function paramListByTypeCode(param) {
    return request({
        url: '/parameter/listByTypeCode',
        method: 'get',
        params: {
            ...param
        }
    })
}

export function delParam(param) {
    return request({
        url: '/parameter/delete',
        method: 'delete',
        params: {
            ...param
        }
    })
}

export function delParamType(param) {
    return request({
        url: '/parameter/type/delete',
        method: 'delete',
        params: {
            ...param
        }
    })
}