import request from "../utils/request";

export function saveRole(data) {
    return request({
        url: '/role/save',
        method: 'post',
        data
    })
}

export function roleList(param, data) {
    return request({
        url: '/role/list',
        method: 'post',
        data,
        params: {
            ...param
        }
    })
}

export function saveRoleMenu(param, data) {
    return request({
        url: '/role/menu/save',
        method: 'post',
        data,
        params: {
            ...param
        }
    })
}

export function del(param) {
    return request({
        url: '/role/delete',
        method: 'delete',
        params: {
            ...param
        }
    })
}

export function menuChecked(param) {
    return request({
        url: '/role/menu/checked',
        method: 'get',
        params: {
            ...param
        }
    })
}