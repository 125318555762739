// VueJS
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import './permission'
import enUsLocal from './lang/en_US'
import zhCnLocal from './lang/zh_CN'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh_CN',
  messages: {
    en_US: {
      ...enUsLocal
    },
    zh_CN: {
      ...zhCnLocal
    },
  }
})
// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);
import FormModel from 'ant-design-vue';
Vue.use(FormModel);
// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

// Template Layouts
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import EmptyLayout from './layouts/Layout'

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);
Vue.component("layout-empty", EmptyLayout);

// Main application view
import App from './App.vue'

// Vue Router
import router from './router'
import store from './store'

// App Styling
import './scss/app.scss';

Vue.config.productionTip = false

// Initialize Vue
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')