export default {
    login: '登录',
    logout: '退出登录',
    add: '新增',
    create: '创建',
    edit: '编辑',
    delete: '删除',
    lastModifiedDate: '最后修改时间',
    lastModifiedUser: '最后修改用户',
    operation: '操作',
    submit: '提交',
    upload: '点击上传',
    delConfirmMsg: '删除后不可恢复，确定删除吗？',
    isCommon: '是否公用',
    system: '系统',
    custom: '客制化',
    invalid: '失效',
    effective: '生效',
    enable: '启用',
    yes: '是',
    no: '否',
    text: '文本',
    radio: '单选',
    checkbox: '多选',
    preview: '预览',
    role: {
        roleManage: '用户角色',
        roleList: '角色列表',
        name: '角色名称',
        code: '角色编码',
        type: '角色类型',
        status: '状态',
        menu: '菜单权限'
    },
    rule: {
        moduleName: '权限管理',
    },
    tenant: {
        code: '租户编码',
        tenant: '租户',
        tenantManage: '租户管理',
        name: '租户名称',
        status: '状态',
        logo: 'Logo',
        domain: '域名'
    },
    param: {
        paramManage: '参数管理',
        name: '参数名称',
        code: '参数编码',
        type: '参数类型',
        typeCode: '参数类型编码',
        typeName: '参数类型名称',
        status: '状态',
        detail: '参数详情'
    },
    user: {
        list: '用户列表',
        avatar: '头像',
        username: '用户名',
        nickname: '昵称',
        mobile: '手机号',
        email: '邮箱',
        role: '用户角色',
        status: '帐号状态',
        bindTenant: '绑定租户'
    },
    questionnaire: {
        moduleName: '调查问卷',
        list: '问卷列表',
        detail: '问卷详情',
        my: '我的问卷',
        title: '问卷标题',
        status: '状态',
        add: '添加主题',
        type: '类型',
        isRequired: '是否必填',
        topic: '主题',
        option: '选项',
        addOption: '添加选项',
        delOption: '删除选项'
    }
}