import {
    deleteQuestionnaire, deleteQuestionnaireTopic,
    getDetail,
    questionnaireList,
    saveQuestionnaire, saveQuestionnaireAnswer,
    saveQuestionnaireTopic
} from "../../api/questionnaire";


const state = {
    questionnaire: []
}

const mutations = {
    SET_QUESTIONNAIRE: (state, questionnaire) => {
        state.questionnaire = questionnaire
    }
}

const actions = {
    SAVE_QUESTIONNAIRE({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveQuestionnaire(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    SAVE_QUESTIONNAIRE_TOPIC({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveQuestionnaireTopic(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    SAVE_QUESTIONNAIRE_ANSWER({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveQuestionnaireAnswer(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    QUESTIONNAIRE_LIST({ commit }, data) {
        return new Promise((resolve, reject) => {
            questionnaireList(data.params, data.data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    QUESTIONNAIRE_DETAIL({ commit }, data) {
        return new Promise((resolve, reject) => {
            getDetail(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    DELETE_QUESTIONNAIRE({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteQuestionnaire(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    DELETE_QUESTIONNAIRE_TOPIC({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteQuestionnaireTopic(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    namespace: false,
    state,
    mutations,
    actions
}