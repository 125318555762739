import {del, menuChecked, roleList, saveRole, saveRoleMenu} from "../../api/role";

const state = {
    roles: []
}

const mutations = {
    SET_ROLES: (state, roles) => {
        state.roles = roles
    }
}

const actions = {
    SAVE_ROLE({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveRole(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    ROLE_LIST({ commit }, data) {
        return new Promise((resolve, reject) => {
            roleList(data.params, data.data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    SAVE_ROLE_MENU({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveRoleMenu(data.params, data.data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    DEL_ROLE({ commit }, param) {
        return new Promise((resolve, reject) => {
            del(param).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    MENU_CHECKED({ commit }, param) {
        return new Promise((resolve, reject) => {
            menuChecked(param).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    namespace: false,
    state,
    mutations,
    actions
}