import {del, saveTenant, tenantList} from "../../api/tenant";
const state = {
    tenant: []
}

const mutations = {
    SET_TENANT: (state, tenant) => {
        state.tenant = tenant
    }
}

const actions = {
    SAVE_TENANT({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveTenant(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    TENANT_LIST({ commit }, data) {
        return new Promise((resolve, reject) => {
            tenantList(data.params, data.data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    DEL_TENANT({ commit }, param) {
        return new Promise((resolve, reject) => {
            del(param).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    namespace: false,
    state,
    mutations,
    actions
}