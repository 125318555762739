import router from "./router"
import store from "./store"
import message from "ant-design-vue/lib/message"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from "./utils/auth"
import {extractMenuCode} from "./utils/common";

NProgress.configure({ showSpinner: false })
const whiteList = ['/login', '/questionnaire/form']

router.beforeEach(async(to, from, next) => {
    NProgress.start()
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else if (to.path === '/404') {
            next()
        } else {
            const hasRoles = store.getters.roles && store.getters.roles.length > 0
            if (hasRoles || whiteList.indexOf(to.path) !== -1) {
                let codes = store.getters.roles
                if (codes.includes(to.meta.code) || whiteList.indexOf(to.path) !== -1) {
                    next()
                } else {
                    next({ path: '/404' })
                }
            } else {
                const res  = await store.dispatch('GET_MENU_TREE');
                if(res.errorCode === '0') {
                    let codes = []
                    codes = extractMenuCode(res.data, codes)
                    store.commit('SET_MENU_CODES', codes)
                    console.log('permission', store.getters.roles)
                    if (codes.includes(to.meta.code)) {
                        next()
                    } else {
                        next({ path: '/404' })
                    }
                } else {
                    next({ path: '/404' })
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})


router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})