import _ from 'lodash'
export function extractMenuCode(menuTree, codes) {
    if (menuTree.meta && menuTree.meta.code) {
        codes.push(menuTree.meta.code)
    }
    if (menuTree.children  && menuTree.children.length > 0) {
        menuTree.children.forEach(x => {
            codes = extractMenuCode(x, codes)
        })
    }
    return codes
}

export function extractMenuCodeAndIdRef(menuTree, ref) {
    if (menuTree.meta && menuTree.meta.code) {
        ref[menuTree.meta.code] = menuTree.id
    }
    if (menuTree.children  && menuTree.children.length > 0) {
        menuTree.children.forEach(x => {
            ref = extractMenuCodeAndIdRef(x, ref)
        })
    }
    return ref
}

export function rebuildMenus(menus, codes, useIsShow) {
    let newMenus = []
    menus.forEach(x => {
        if (x.meta && x.meta.code && codes.includes(x.meta.code) && (!useIsShow || (x.meta.isShow && x.meta.isShow))) {
            let temp = _.cloneDeep(x)
            temp.children = []
            if (x.children && x.children.length > 0) {
                temp.children = rebuildMenus(x.children, codes, useIsShow)
            }
            newMenus.push(temp)
        }
    })
    return newMenus
}

export function constructMenuTree(menus, ref, i18n) {
    let menuTree = []
    menus.forEach(x => {
        let data = {
            title: i18n.t(x.name),
            key: ref[x.meta.code]
        }
        if (x.children && x.children.length > 0) {
            data.children = constructMenuTree(x.children, ref, i18n)
        }
        menuTree.push(data)
    })
    return menuTree
}

export function getTenantCode(username) {
    let domain = document.domain
    let tenantCode = domain.substring(0, domain.indexOf("."))
    tenantCode = tenantCode === 'ui' ? '' : tenantCode
    if (!tenantCode) {
        tenantCode = username.substring(0, username.indexOf("/"))
    }
    tenantCode = tenantCode ? tenantCode.toUpperCase() : tenantCode
    return tenantCode
}