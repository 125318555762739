<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent', boxShadow: '4px 4px 4px rgba(0,0,0,.1)'}">
			<div class="brand"><img src="images/logo-ct-black.png" alt=""> <span style="background: linear-gradient(to right, #30cfd0, #330867);
        -webkit-background-clip: text;
        color: transparent">CuteMeet</span></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline"
      			:open-keys="openKeys"
				@openChange="onOpenChange">
        <template v-for="firstLevelMenu in menus">
          <a-menu-item class="menu-item-header" :key="firstLevelMenu.name + '-module'">
            {{ $t(firstLevelMenu.name) }}
          </a-menu-item>
          <a-sub-menu style="padding: 0;" v-for="secondLevelMenu in firstLevelMenu.children" :key="secondLevelMenu.meta.code" popupClassName="subMenu">
            <span slot="title">
              <span class="icon" style="font-size: 13px !important;">
                <a-icon :type="secondLevelMenu.meta.icon" theme="filled" class="m-0" />
              </span>
              <span class="label" style="font-size: 13px !important;">{{ $t(secondLevelMenu.name) }}</span>
            </span>
            <a-menu-item-group>
              <template v-for="thirdLevelMenu in secondLevelMenu.children">
                <a-menu-item v-if="!thirdLevelMenu.children || thirdLevelMenu.children.length < 1" :key="thirdLevelMenu.meta.code">
                  <router-link :to="thirdLevelMenu.path">
                    <span class="label" style="font-size: 13px !important;">{{ $t(thirdLevelMenu.name) }}</span>
                  </router-link>
                </a-menu-item>
                <a-sub-menu v-if="thirdLevelMenu.children && thirdLevelMenu.children.length > 0" :key="thirdLevelMenu.meta.code" style="padding: 0;" :title="thirdLevelMenu.name">
                  <a-menu-item-group>
                    <a-menu-item v-for="fourthLevelMenu in thirdLevelMenu.children" :key="fourthLevelMenu.meta.code">
                      <router-link :to="fourthLevelMenu.path">
                        <span class="label" style="font-size: 13px !important;">{{ $t(fourthLevelMenu.name) }}</span>
                      </router-link>
                    </a-menu-item>
                  </a-menu-item-group>
                </a-sub-menu>
              </template>
            </a-menu-item-group>
          </a-sub-menu>
        </template>
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
			<div class="aside-footer">
			</div>
			<!-- / Sidebar Footer -->

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

	import Layout from "@/layouts/Layout";
  import {extractMenuCode, rebuildMenus} from "../../utils/common";
  import store from "../../store";

  export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
			return {
        rootSubmenuKeys: [],
				openKeys: this.$route.meta.sidebarMap,
        menus: []
			}
		},
    created() {
      this.initRoute()
    },
    methods: {
      initRoute() {
        let modules = []
        let menus = []
        let codes = []
        this.$store.dispatch('GET_MENU_TREE').then(res => {
          if(res.errorCode === '0') {
            codes = extractMenuCode(res.data, codes)
            this.$store.commit('SET_MENU_CODES', codes)
            const routes = rebuildMenus(this.$router.options.routes, codes, true)
            routes.forEach(item => {
              if (item.meta.sidebarMap){
                item.meta.sidebarMap.forEach( x => {
                  if (!this.rootSubmenuKeys.includes(x)) {
                    this.rootSubmenuKeys.push(item.meta.sidebarMap[0])
                  }
                })
              }
              if (item.meta.module && !modules.includes(item.meta.module)){
                modules.push(item.meta.module)
              }
            })
            modules.forEach(module => {
              let moduleRoute = {
                name: module,
                children: [],
                type: 'module'
              }
              routes.forEach(route => {
                if (route.meta && route.meta.module === module) {
                  moduleRoute.children.push(route)
                }
              })
              menus.push(moduleRoute)
            })
            this.menus = menus
          }
        })
      },
			onOpenChange(openKeys)
			{
				const latestOpenKey = openKeys.find( key => this.openKeys.indexOf( key ) === -1) ;
				if ( this.rootSubmenuKeys.indexOf( latestOpenKey ) === -1 )
				{
					this.openKeys = openKeys;
				}
				else
				{
					this.openKeys = latestOpenKey ? [ latestOpenKey ] : [] ;

        }
        console.log("openKeys", openKeys)
        console.log("this.openKeys", this.openKeys)
			},
      onSelected(item, key, selectedKeys) {
        console.log('onSelected', item, key, selectedKeys)
      }
		}
	})

</script>
<style>
.subMenu{
  font-size: 13px !important;
}
</style>