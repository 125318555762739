import request from "../utils/request";

export function saveTenant(data) {
    return request({
        url: '/tenant/save',
        method: 'post',
        data
    })
}

export function tenantList(param, data) {
    return request({
        url: '/tenant/list',
        method: 'post',
        data,
        params: {
            ...param
        }
    })
}

export function del(param) {
    return request({
        url: '/tenant/delete',
        method: 'delete',
        params: {
            ...param
        }
    })
}