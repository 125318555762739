import Vue from 'vue'
import VueRouter from 'vue-router'
import docRouter from '@/router/doc'

import Layout from '../layouts/Layout'

Vue.use(VueRouter)

let routes = [
	// {
	// 	// will match everything
	// 	path: '*',
	// 	component: () => import('../views/404.vue'),
	// },
	{
		// will match everything
		path: '/404',
		layout: 'dashboard',
		component: () => import('../views/404.vue'),
		meta: {
			title: '404',
			sidebarMap: ['404'],
			breadcrumbs: ['404'],
			module: '404',
			code: '404',
			icon: 'code'
		}
	},
	{
		path: '/questionnaire/form',
		name: 'questionnaire.form',
		layout: 'empty',
		meta: {
			title: 'questionnaire.form',
			sidebarMap: ['QUESTIONNAIRE', 'QUESTIONNAIRE-FORM'],
			breadcrumbs: ['questionnaire.moduleName', 'questionnaire.form'],
			code: 'QUESTIONNAIRE-FORM',
			icon: '',
			isShow: true
		},
		component: () => import('@/views/Questionnaire/Form.vue')
	},
	{
		// will match everything
		path: '/login',
		name: 'login',
		layout: 'empty',
		meta: {
			layoutClass: 'layout-sign-up-illustration',
			title: 'login',
			sidebarMap: [],
			breadcrumbs: [],
			nofooter: true,
		},
		component: () => import('../views/Login'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboards',
	},
	{
		path: '/dashboards',
		name: 'Dashboard',
		component: Layout,
		layout: 'dashboard',
		redirect: '/dashboards/index',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		meta: {
			title: 'Default Dashboard',
			sidebarMap: ['DASHBOARD'],
			breadcrumbs: ['Dashboards', 'Default'],
			module: 'DASHBOARD',
			code: 'DASHBOARD',
			icon: 'appstore',
			isShow: true
		},
		children: [
			{
				path: '/dashboards/index',
				name: 'Dashboard',
				meta: {
					title: 'Default Dashboard',
					sidebarMap: ['DASHBOARD', 'DASHBOARD-INDEX'],
					breadcrumbs: ['Dashboards', 'Default'],
					code: 'DASHBOARD-INDEX',
					icon: '',
					isShow: true
				},
				component: () => import(/* webpackChunkName: "dashboard" */ '../views/Index.vue')
			}
		]
	},
	{
		path: '/questionnaire',
		name: 'questionnaire.moduleName',
		component: Layout,
		layout: 'dashboard',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		meta: {
			title: 'questionnaire.moduleName',
			sidebarMap: ['QUESTIONNAIRE'],
			breadcrumbs: ['questionnaire.moduleName'],
			module: 'questionnaire.moduleName',
			code: 'QUESTIONNAIRE',
			icon: 'copy',
			isShow: true
		},
		children: [
			{
				path: '/questionnaire/index',
				name: 'questionnaire.my',
				meta: {
					title: 'questionnaire.my',
					sidebarMap: ['QUESTIONNAIRE', 'QUESTIONNAIRE-INDEX'],
					breadcrumbs: ['questionnaire.moduleName', 'questionnaire.my'],
					code: 'QUESTIONNAIRE-INDEX',
					icon: '',
					isShow: true
				},
				component: () => import('@/views/Questionnaire/Index.vue')
			}
		]
	},
	{
		path: '/rule',
		name: 'rule.moduleName',
		component: Layout,
		layout: 'dashboard',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		meta: {
			title: 'rule.moduleName',
			sidebarMap: ['RULE'],
			breadcrumbs: ['Rule'],
			module: 'rule.moduleName',
			code: 'RULE',
			icon: 'code',
			isShow: true
		},
		children: [
			{
				path: '/tenant',
				name: 'tenant.tenantManage',
				meta: {
					title: 'tenant.tenantManage',
					sidebarMap: ['RULE', 'TENANT'],
					breadcrumbs: ['rule.moduleName', 'tenant.tenantManage'],
					code: 'TENANT',
					icon: '',
					isShow: true
				},
				component: () => import('@/views/Tenant/Index.vue')
			},
			{
				path: '/user',
				name: 'user.list',
				meta: {
					title: 'user.list',
					sidebarMap: ['RULE', 'USER'],
					breadcrumbs: ['rule.moduleName', 'user.list'],
					code: 'USER',
					icon: '',
					isShow: true
				},
				component: () => import('@/views/Users/Index.vue')
			},
			{
				path: '/rule/role',
				name: 'role.roleManage',
				meta: {
					title: 'role.roleManage',
					sidebarMap: ['RULE', 'RULE-ROLE'],
					breadcrumbs: ['rule.moduleName', 'role.roleManage'],
					code: 'RULE-ROLE',
					icon: '',
					isShow: true
				},
				component: () => import('@/views/Role/Index.vue')
			},
			{
				path: '/parameter',
				name: 'param.paramManage',
				meta: {
					title: 'param.paramManage',
					sidebarMap: ['RULE', 'PARAMETER'],
					breadcrumbs: ['rule.moduleName', 'param.paramManage'],
					code: 'PARAMETER',
					icon: '',
					isShow: true
				},
				component: () => import('@/views/Parameter/Index.vue')
			},
			{
				path: '/parameter/detail',
				name: 'param.detail',
				meta: {
					title: 'param.detail',
					sidebarMap: ['RULE', 'PARAMETER'],
					breadcrumbs: ['rule.moduleName', 'param.paramManage'],
					code: 'PARAMETER-DETAIL',
					icon: '',
					isShow: false
				},
				component: () => import('@/views/Parameter/Detail.vue')
			}
		]
	}
]

docRouter.forEach(x => {
	routes.push(x)
})
routes.push({ path: '*', redirect: '/404', hidden: true })

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;

	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) )

const originalPath = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPath.call(this, location, onResolve, onReject)
	}
	return originalPath.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
