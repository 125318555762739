import {menuTree} from "../../api/menu";

const state = {
    menuTree: [],
    menuCodes: []
}

const mutations = {
    SET_MENU_TREE: (state, menuTree) => {
        state.menuTree = menuTree
    },
    SET_MENU_CODES: (state, menuCodes) => {
        state.menuCodes = menuCodes
    }
}

const actions = {
    GET_MENU_TREE({commit}) {
        return new Promise((resolve, reject) => {
            menuTree().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    SET_SELECTED_KEYS_ACTION({commit}, data) {
        commit('SET_SELECTED_KEYS', data)
    },
    GET_SELECTED_KEYS_ACTION({commit}, data) {
        commit('SET_SELECTED_KEYS', data)
    },
}

export default {
    namespace: false,
    state,
    mutations,
    actions
}