import {
    delParam,
    delParamType,
    paramList,
    paramListByTypeCode,
    paramTypeList,
    saveParam,
    saveParamType
} from "../../api/parameter";

const state = {
    param: []
}

const mutations = {
    SET_PARAM: (state, param) => {
        state.param = param
    }
}

const actions = {
    SAVE_PARAM_TYPE({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveParamType(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    SAVE_PARAM({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveParam(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    PARAM_TYPE_LIST({ commit }, data) {
        return new Promise((resolve, reject) => {
            paramTypeList(data.params, data.data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    PARAM_LIST({ commit }, data) {
        return new Promise((resolve, reject) => {
            paramList(data.params, data.data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    PARAM_LIST_BY_TYPE_CODE({ commit }, data) {
        return new Promise((resolve, reject) => {
            paramListByTypeCode(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    DEL_PARAM_TYPE({ commit }, param) {
        return new Promise((resolve, reject) => {
            delParamType(param).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    DEL_PARAM({ commit }, param) {
        return new Promise((resolve, reject) => {
            delParam(param).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    namespace: false,
    state,
    mutations,
    actions
}