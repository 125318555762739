<!--
	This is the default layout, used in sign-in and sign-up pages.
 -->

<template>
  <div>
    <router-view />
  </div>
</template>

<script>


export default ({
  data() {
    return {
    }
  }
})

</script>